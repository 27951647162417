// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-page-list-js": () => import("./../src/components/page-list.js" /* webpackChunkName: "component---src-components-page-list-js" */),
  "component---src-components-page-js": () => import("./../src/components/page.js" /* webpackChunkName: "component---src-components-page-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-discovery-js": () => import("./../src/pages/discovery.js" /* webpackChunkName: "component---src-pages-discovery-js" */),
  "component---src-pages-gurps-mashup-js": () => import("./../src/pages/gurps-mashup.js" /* webpackChunkName: "component---src-pages-gurps-mashup-js" */),
  "component---src-pages-tools-js": () => import("./../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */)
}

